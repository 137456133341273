import { Navigate, useRoutes, Outlet } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';

//
import DashboardApp from './pages/DashboardApp';
import NotFound from './pages/Page404';
import { MessageForm } from './pages/Message';
import { ReportsPage } from './pages/Reports';
import LoadExcel from './pages/Message/LoadExcel';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        {
          path: 'app',
          element: <DashboardApp />
        }
      ]
    },
    {
      path: '/messages',
      element: <DashboardLayout />,
      children: [
        {
          path: 'sendText',
          element: <MessageForm />
        },
        {
          path: 'loadExcel',
          element: <LoadExcel />
        }
      ]
    },
    {
      path: '/reports',
      element: <DashboardLayout />,
      children: [
        {
          path: 'messages',
          element: <ReportsPage />
        }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
