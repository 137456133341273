import * as yup from 'yup';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

// material
import {
  Stack,
  Button,
  Container,
  Typography,
  TextField,
  IconButton,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';

// components
import { getIn } from 'formik';
import { useToasts } from '../../hooks/useToasts';
import Page from '../../components/Page';
import { getMessages } from '../../services/MessageService';

// ----------------------------------------------------------------------

const FileInput = styled('input')({
  display: 'none'
});

const FileLabel = styled('label')();

export default function ReportsPage() {
  const toast = useToasts();
  const [value, setValue] = React.useState(moment());
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [llave, setLlave] = useState('');
  const [statistics, setStatistics] = useState({ total: 0, sent: 0 });
  const [pagesize, setPagesize] = useState(10);
  const loadMessages = async (allmonth = false) => {
    if (llave === '') {
      toast.error(`Debe ingresar una llave`);
    } else {
      setLoading(true);
      try {
        const r = await getMessages({ date: value.format('YYYY-MM-DD'), authkey: llave, allmonth });
        setStatistics({ total: r.size, sent: r.data.filter((e) => e.enviado).length });
        r.data = r.data.map((m) => ({
          ...m,
          date: new Date(m.date),
          id: m._id,
          destino: `+${m.destino.replace('@c.us', '')}`
        }));
        setMessages(r.data);
        setLoading(false);
      } catch (e) {
        toast.error(`Ha ocurrido un error`);
        setLoading(false);
      }
    }
  };

  const handleChangeDate = (newValue) => {
    setValue(newValue);
  };

  const columns = [
    {
      field: 'date',
      headerName: 'Fecha',
      type: 'dateTime',
      width: 200,
      editable: false
    },
    {
      field: 'destino',
      headerName: 'Destino',
      width: 150,
      editable: false
    },
    {
      field: 'enviado',
      headerName: 'Enviado',
      flex: 0.2,
      type: 'boolean',
      sortable: true,
      editable: false
    },
    {
      field: 'mensaje',
      headerName: 'Mensaje',
      flex: 1,
      sortable: false,
      editable: false
    }
  ];

  return (
    <Page title="Reporte de Mensajes">
      <Container>
        <Stack sx={{ mb: 5 }}>
          <Typography variant="h4" gutterBottom>
            Reporte de Mensajes
          </Typography>
        </Stack>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Stack sx={{ mb: 5 }}>
            <TextField
              fullWidth
              label="Llave"
              value={llave}
              onChange={(e) => setLlave(e.target.value)}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="flex-end" spacing={3}>
            <span>Total: {statistics.total}</span>
            <span>Enviados: {statistics.sent}</span>
            <span>Fallidos: {statistics.total - statistics.sent}</span>
            <DesktopDatePicker
              label="Día de Reporte"
              inputFormat="MM/DD/YYYY"
              value={value}
              onChange={handleChangeDate}
              renderInput={(params) => <TextField {...params} />}
            />
            <LoadingButton
              variant="contained"
              sx={{ mt: 2 }}
              loading={loading}
              onClick={() => loadMessages(false)}
            >
              Cargar
            </LoadingButton>
            <LoadingButton
              variant="contained"
              sx={{ mt: 2 }}
              loading={loading}
              onClick={() => loadMessages(true)}
            >
              Cargar todo el mes
            </LoadingButton>
          </Stack>
        </LocalizationProvider>
        <Stack>
          <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                loading={loading}
                autoHeight
                rows={messages}
                disableDensitySelector
                columns={columns}
                pageSize={pagesize}
                onPageSizeChange={(newPageSize) => setPagesize(newPageSize)}
                rowsPerPageOptions={[10, 50, 100]}
                components={{ Toolbar: GridToolbar }}
              />
            </div>
          </div>
        </Stack>
      </Container>
    </Page>
  );
}
