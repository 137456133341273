import * as yup from 'yup';
import React, { useEffect, useState, useRef } from 'react';
import { useFormik, Form, FormikProvider, getIn } from 'formik';
// material
import { Stack, Container, Typography, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import * as XLSX from 'xlsx';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

// components
import Page from '../../components/Page';
import { sendMessage, sendMessages } from '../../services/MessageService';
import { useToasts } from '../../hooks/useToasts';

// ----------------------------------------------------------------------

const FileInput = styled('input')({
  display: 'none'
});

export default function LoadExcel() {
  const [loadedData, setLoadedData] = useState([]);
  const [pagesize, setPagesize] = useState(10);

  const toast = useToasts();

  const columns = [
    {
      field: 'phone',
      headerName: 'Teléfono',
      type: 'string',
      flex: 0.3,
      editable: false
    },
    {
      field: 'message',
      headerName: 'Mensaje',
      flex: 0.7,
      editable: false
    }
  ];

  const validationSchema = yup.object({
    key: yup.string().required('Campo requerido')
  });

  const formik = useFormik({
    initialValues: {
      key: ''
    },
    validationSchema,
    onSubmit: async (values) => {
      if (loadedData.length > 0) {
        const r = await sendMessages({
          key: values.key,
          messages: loadedData
        });

        if (r && r.OK) {
          toast.success(`Se enviaron los mensajes`);
        } else {
          toast.error(`Ha ocurrido un error`);
        }
      }
    }
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    setSubmitting
  } = formik;

  const fileInputRef = useRef(null);

  const handleLoadExcel = (e) => {
    e.preventDefault();

    const { files } = e.target;
    const f = files[0];
    const reader = new FileReader();

    reader.onload = function (e) {
      const data = e.target.result;
      const readData = XLSX.read(data, { type: 'binary' });
      const wsname = readData.SheetNames[0];
      const ws = readData.Sheets[wsname];

      try {
        /* Convert array to json */
        const dataParse = XLSX.utils.sheet_to_json(ws, { header: 0 });

        if (dataParse && dataParse.length > 0) {
          if (
            Object.keys(dataParse[0]).indexOf('message') >= 0 &&
            Object.keys(dataParse[0]).indexOf('phone') >= 0
          )
            setLoadedData(
              dataParse.map((x) => ({
                message: x.message,
                phone: x.phone.toString(),
                id: x.__rowNum__
              }))
            );
          else setLoadedData([]);
        }
      } catch (e) {
        console.log(e);
        setLoadedData([]);
      }
    };
    reader.readAsBinaryString(f);
  };

  console.log(loadedData);
  return (
    <Page title="Mensajes">
      <Container>
        <Stack sx={{ mb: 5 }}>
          <Typography variant="h4" gutterBottom>
            Enviar mensajes
          </Typography>
        </Stack>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                multiline
                label="LLave"
                {...getFieldProps('key')}
                error={getIn(touched, `key`) && Boolean(getIn(errors, `key`))}
                helperText={getIn(touched, `key`) && getIn(errors, `key`)}
              />
            </Stack>
            <Stack sx={{ display: 'flex', alignItems: 'end' }}>
              <FileInput
                name="file"
                type="file"
                accept=".xlsx, .xls"
                ref={fileInputRef}
                onChange={handleLoadExcel}
              />
              <LoadingButton
                variant="contained"
                sx={{ mt: 2 }}
                loading={false}
                onClick={() => fileInputRef.current.click()}
              >
                Cargar Excel
              </LoadingButton>
            </Stack>
            {loadedData.length > 0 && (
              <Stack>
                <div style={{ display: 'flex', height: '100%' }}>
                  <div style={{ flexGrow: 1 }}>
                    <DataGrid
                      autoHeight
                      rows={loadedData}
                      disableDensitySelector
                      columns={columns}
                      pageSize={pagesize}
                      onPageSizeChange={(newPageSize) => setPagesize(newPageSize)}
                      rowsPerPageOptions={[10, 50, 100]}
                      getRowId={(x) => x.id}
                    />
                  </div>
                </div>
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  sx={{ mt: 2 }}
                  loading={isSubmitting}
                >
                  Enviar
                </LoadingButton>
              </Stack>
            )}
          </Form>
        </FormikProvider>
      </Container>
    </Page>
  );
}
