import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Ctx = React.createContext();

const Alert = React.forwardRef((props, ref) => (
  <MuiAlert elevation={1} ref={ref} variant="filled" {...props} />
));

function ToastContainer(props) {
  return <div style={{ position: 'fixed', right: 0, top: 0, zIndex: 2000 }} {...props} />;
}

function Toast({ type, text, onClose, open }) {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    onClose();
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
        {text}
      </Alert>
    </Snackbar>
  );
}

export function ToastProvider({ children }) {
  const [t, setToast] = React.useState({ open: false });

  const error = (content) => {
    const toast = { content, type: 'error', open: true };
    setToast(toast);
  };

  const warning = (content) => {
    const toast = { content, type: 'warning', open: true };
    setToast(toast);
  };

  const info = (content) => {
    const toast = { content, type: 'info', open: true };
    setToast(toast);
  };

  const success = (content) => {
    const toast = { content, type: 'success', open: true };
    setToast(toast);
  };

  const value = React.useMemo(
    () => ({
      error,
      warning,
      info,
      success
    }),
    []
  );

  const onDismiss = () => setToast({ ...t, open: false });

  return (
    <Ctx.Provider value={value}>
      {children}
      <ToastContainer>
        <Toast open={t.open} onClose={() => onDismiss()} text={t.content} type={t.type} />
      </ToastContainer>
    </Ctx.Provider>
  );
}

export const useToasts = () => React.useContext(Ctx);
