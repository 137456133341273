import * as yup from 'yup';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik, Form, FormikProvider, FieldArray, getIn } from 'formik';
// material
import {
  Stack,
  Button,
  Container,
  Typography,
  TextField,
  IconButton,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';

// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import { sendMessage } from '../../services/MessageService';
import { useToasts } from '../../hooks/useToasts';

// ----------------------------------------------------------------------

const FileInput = styled('input')({
  display: 'none'
});

const FileLabel = styled('label')();

export default function MessageForm() {
  const toast = useToasts();

  const validationSchema = yup.object({
    phones: yup
      .string()
      .required('Campo requerido')
      .matches(/^[0-9 ]+[0-9]$/, 'Solo números separados por espacios'),
    message: yup.string().required('Campo requerido'),
    key: yup.string().required('Campo requerido')
  });

  const formik = useFormik({
    initialValues: {
      phones: '',
      key: '',
      message: ''
    },
    validationSchema,
    onSubmit: async (values) => {
      const r = await sendMessage(values);
      if (r && r.OK) {
        toast.success(`Se enviaron ${r.OK} mensajes`);
      } else {
        toast.error(`Ha ocurrido un error`);
      }
    }
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    setSubmitting
  } = formik;

  return (
    <Page title="Mensajes">
      <Container>
        <Stack sx={{ mb: 5 }}>
          <Typography variant="h4" gutterBottom>
            Enviar mensaje
          </Typography>
        </Stack>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                multiline
                label="LLave"
                {...getFieldProps('key')}
                error={getIn(touched, `key`) && Boolean(getIn(errors, `key`))}
                helperText={getIn(touched, `key`) && getIn(errors, `key`)}
              />
              <TextField
                fullWidth
                multiline
                label="Teléfonos (separados por espacios)"
                {...getFieldProps('phones')}
                error={getIn(touched, `phones`) && Boolean(getIn(errors, `phones`))}
                helperText={getIn(touched, `phones`) && getIn(errors, `phones`)}
              />
              <TextField
                fullWidth
                multiline
                label="Mensaje"
                {...getFieldProps('message')}
                error={getIn(touched, `message`) && Boolean(getIn(errors, `message`))}
                helperText={getIn(touched, `message`) && getIn(errors, `message`)}
              />
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                sx={{ mt: 2 }}
                loading={isSubmitting}
              >
                Enviar
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </Container>
    </Page>
  );
}
