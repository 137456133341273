const sidebarConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: 'eva:pie-chart-2-fill'
  },
  {
    title: 'mensajes',
    icon: 'eva:file-text-fill',
    children: [
      {
        title: 'enviar texto',
        path: '/messages/sendText'
      },
      {
        title: 'cargar excel',
        path: '/messages/loadExcel'
      }
    ]
  },
  {
    title: 'reportes',
    icon: 'eva:file-text-fill',
    children: [
      {
        title: 'Reporte de mensajes',
        path: '/reports/messages'
      }
    ]
  }
];

export default sidebarConfig;
