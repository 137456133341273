import axios from 'axios';

const URL_API = process.env.REACT_APP_API_URL;

async function sendMessage(data) {
  const axiosClient = axios.create({
    baseURL: URL_API,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${data.key}`
    }
  });

  const numbersList = data.phones.split(' ');

  if (numbersList.length === 1) {
    const result = await axiosClient.post('/v1/wa-message', {
      phone: numbersList[0],
      message: data.message
    });

    const okCount = result.data === 'OK' ? 1 : 0;
    return { OK: okCount };
  }
  const messages = numbersList.map((x) => ({
    phone: x,
    message: data.message
  }));

  return sendMessages(messages);
}

async function sendMessages(data) {
  const axiosClient = axios.create({
    baseURL: URL_API,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${data.key}`
    }
  });

  const result = await axiosClient.post('/v1/wa-messages', data.messages);
  return { OK: result.data };
}

async function getMessages(data) {
  const axiosClient = axios.create({
    baseURL: URL_API,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${data.authkey}`
    }
  });

  const results = await axiosClient.post('/v1/report', {
    allmonth: data.allmonth,
    date: data.date
  });
  return results.data;
}

export { sendMessage, getMessages, sendMessages };
